body {
    background-color: #eeeeee;
    color: black;
    font-family: 'Raleway';
}

button {
	cursor: pointer;
}
button:focus {outline:0;}

input:focus::-webkit-input-placeholder 
{
    color: transparent;
}
a {
  cursor: pointer;
}

.hyperlink {
    color: #5555ff;
}