body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.btn-primary {
  background-color: #4f7d84!important;
  border-color: #4f7d84!important;
}
