
.image-upload .fileContainer {
  box-shadow:    none;
  border-radius: 0;
}

.web-input {
  min-width: 400px;
}

.img-preview {
  max-height: 400px;
}

.no-img-msg {
  display:          block;
  padding:          15px;
  border-radius:    5px;
  margin:           auto;
  text-align:       center;
  background-color: #E3E3E3;
  flex-grow:        1;
}
