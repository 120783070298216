
.tab {
  background-color:    #E6E6E6;
  padding:             15px 50px;
  text-align:          center;
  cursor:              pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select:    none; /* Firefox */
  -ms-user-select:     none; /* IE10+/Edge */
  user-select:         none; /* Standard */
  flex-grow:           1;
  transition:          all .2s;
}

.tab.selected {
  background-color: #477177;
  color:            white;
}

.tab:hover {
  background-color: #689DA5;
  color:            white;
}

.tab.selected:hover {
  background-color: #477177;
  color:            white;
}

.hidden {
  display: none;
}
