.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: auto !important;
}


.react-datepicker__time-container--with-today-button {
  right: -77px !important;
}


.react-datepicker__time-container {
  width: auto !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(246px + (1.7rem / 2)) !important;
}

.react-datepicker__input-container input {
  width: 200px;
  height: calc(2.25rem + 2px);
}
