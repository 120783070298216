.page-link {
  color: #477177;
}

.page-link:hover {
  color: #477177;
}

.page-item.active .page-link {
  background-color: #477177 !important;
  border-color: #477177 !important;
}
