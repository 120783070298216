.modal-content {
    width: 450px;
}

.modal-header h5 {
    color: #bbb;
    font-size: 2em;
    width: 100%;
}

.error {
    color: red;
}

.modal-body input {
    background-color: #ddd;
    border: none;
    height: 50px;
    padding: 1em;
}

.sign-in-button {
    background-color: #477177;
    border: none;
    color: white;
    height: 50px;
}

.sign-in-button:hover {
    background-color: #4F7D84;
}

.modal-footer {
    justify-content: space-around;
}

.modal-footer p {
    cursor: pointer;
    font-size: small;
}

