.article {
  background-color: white;
}

.article-header {
  background-color: lightgrey;
  width:            100%;
}

.article-body {
  width:       100%;
  white-space: pre-wrap;
}

.article-image {
  float:     left;
  max-width: 200px;
}

.article-links {
  width: 100%;
}

.article-footer {
  width: 100%;
}

.category-image {
  max-height: 36px;
  margin-right: 10px;
}