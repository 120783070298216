.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: .5;
}

.clickable:hover {
  opacity: .6;
}

.backArrow {
}

.uploadIcon {
}

.headerRow {
  border-bottom: 1px solid black;
}

.dataRow {
  cursor:           pointer;
  transition:       all .2s;
  background-color: #F8F8F8;
}

.dataRow:nth-child(odd) {
  background-color: #F0F0F0;
}

.dataRow:hover {
  background-color: #BED5D8;
}

.headerCell {
  font-weight: bold;
}

.dataCell {
  font-weight: normal;
}

.iconColumn {
  width: 50px;
}

.nameColumn {
}

.dateColumn {
  width: 200px;
}

.ctaColumn {
  width: 50px;
}
