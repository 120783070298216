.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #477177; /* Blue */
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
